/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Card, FormControl, MenuItem, Select, SelectChangeEvent, Skeleton, Typography } from '@mui/material'
import { Modal } from 'antd'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loader'
import {
  useCellularUsageData,
  useCellularUsageGraph,
  useCellularUsageSummary
} from '../../../../hook/useCellularUsageSummary'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { getColorCode } from '../../../../utils/helper'
import CellularUsageTable from './CellularUsageTable'
import { Close } from '@mui/icons-material'

interface CellularModalProps {
  cellularModalOpen: boolean
  setCellularModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  isDashboard?: boolean
}

// Type definitions
interface ChartDataPoint {
  time: string
  usage: number
  deviceCount: number
}

export interface TableDataRow {
  id: number
  date: string
  device?: string
  startTime: string
  endTime: string
  duration: string
  cellularUsage: string
}

export interface Column {
  id: 'date' | 'startTime' | 'endTime' | 'duration' | 'cellularUsage' | 'device'
  label: string
  minWidth: number
}

const CellularModal: React.FC<CellularModalProps> = ({ cellularModalOpen, setCellularModalOpen, isDashboard }) => {
  const [FilterData, setFilterData] = useState<any>(24)
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  // Calculate the timestamp based on the selected filter value
  const getPastTimestamp = (filterValue: any) => {
    if (filterValue === 24) {
      return moment().subtract(24, 'hours').toISOString()
    } else if (filterValue === 1) {
      return moment().subtract(1, 'hours').toISOString() // For 'Last 1 hr'
    } else if (filterValue === 168) {
      return moment().subtract(7, 'days').toISOString() // For 'This Week'
    } else if (filterValue === 730) {
      return moment().subtract(1, 'months').toISOString() // For 'This Month'
    }
    return moment().toISOString()
  }

  const pastTimestamp = getPastTimestamp(FilterData)

  const { data, isFetching, refetch: cellularUsageDataFetch } = useCellularUsageData(activeGw_Uid, pastTimestamp)

  const {
    data: cellularUsageGraphData,
    isFetching: cellularUsageDataFetchingGraph,
    refetch: cellularUsageGraphRefetch
  } = useCellularUsageGraph(activeGw_Uid, pastTimestamp)

  const {
    data: cellularUsageSummaryData,
    isFetching: cellularUsageSummaryFetching,
    refetch: cellularUsageSummaryFetch
  } = useCellularUsageSummary(activeGw_Uid, pastTimestamp)

  useEffect(() => {
    if (activeGw_Uid !== '' && cellularModalOpen) {
      cellularUsageDataFetch()
      cellularUsageGraphRefetch()
      cellularUsageSummaryFetch()
    }
  }, [activeGw_Uid, cellularModalOpen, FilterData])

  const generateChartData = () => {
    return cellularUsageGraphData?.map((item: any) => ({
      time:
        FilterData === 24 || FilterData === 1
          ? moment.utc(item.time_stamp).local().format('h:mm A') // Format time for Last 24 hours or Live data
          : moment.utc(item.time_stamp).local().format('DD-MM-YYYY'), // Full date for Last 7 Days or Last 30 Days
      downloadUsage: item.download_data_mb,
      uploadUsage: item.upload_data_mb
    }))
  }

  const chartData = generateChartData()

  const calculateAverageUsage = (data: any[]) => {
    if (!data || data?.length === 0) return { downloadAverage: 0, uploadAverage: 0 }

    const totalDownloadUsage = data?.reduce((acc, item) => acc + item?.downloadUsage, 0)
    const totalUploadUsage = data?.reduce((acc, item) => acc + item?.uploadUsage, 0)

    return {
      downloadAverage: totalDownloadUsage / data?.length,
      uploadAverage: totalUploadUsage / data?.length
    }
  }

  const { downloadAverage, uploadAverage } = calculateAverageUsage(chartData)

  const tableData: TableDataRow[] = isDashboard
    ? []
    : data &&
      data?.map((item: any, index: number) => {
        const startTime = moment.utc(item.start_time).local()
        const endTime = moment.utc(item.end_time).local()
        const durationMinutes = item.duration

        return {
          id: index + 1,
          date: startTime.format('MMMM DD, YYYY'),
          startTime: startTime.format('h:mm A'),
          endTime: endTime.format('h:mm A'),
          duration: durationMinutes,
          cellularUsage: item.cellular_usage
        }
      })

  // Column definitions for the table
  const columns: Column[] = [
    { id: 'date', label: 'Date', minWidth: 170 },
    { id: 'device', label: 'DEVICE', minWidth: 170 },
    { id: 'startTime', label: 'Start Time', minWidth: 100 },
    { id: 'endTime', label: 'End Time', minWidth: 100 },
    { id: 'duration', label: 'Duration', minWidth: 100 },
    { id: 'cellularUsage', label: 'Cellular Usage', minWidth: 100 }
  ]

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string
    setFilterData(selectedValue)
  }

  const chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    xAxis: {
      categories: chartData?.map((point: any) => point?.time || ''), // Use empty string if no new date
      title: {
        text: FilterData === 24 || FilterData === 1 ? 'Time' : 'Date',
        margin: 20
      }
    },
    yAxis: [
      {
        title: { text: 'Usage (MB)', margin: 20 },
        gridLineColor: '#e6e6e6'
      },
      {
        title: { text: 'Device Count' },
        opposite: true,
        visible: isDashboard
      }
    ],
    series: isDashboard
      ? [
          {
            type: 'line',
            name: 'Usage',
            data: chartData?.map((point: any) => point?.usage),
            color: getColorCode('CellularUsage')
          },
          {
            type: 'line',
            name: 'Device Count',
            data: chartData?.map((point: any) => point?.deviceCount),
            color: getColorCode('DeviceUsage'),
            yAxis: 1
          }
        ]
      : [
          {
            type: 'line',
            name: 'Download Usage',
            data: chartData?.map((point: any) => point?.downloadUsage),
            color: getColorCode('DownloadUsage')
          },
          {
            type: 'line',
            name: 'Upload Usage',
            data: chartData?.map((point: any) => point?.uploadUsage),
            color: getColorCode('UploadUsage')
          },
          {
            type: 'line',
            name: 'Average Download Usage',
            data: new Array(chartData?.length).fill(downloadAverage),
            color: getColorCode('DownloadUsage'), // Different color for average line
            dashStyle: 'Dash' // Optional: Makes it a dashed line
          },
          {
            type: 'line',
            name: 'Average Upload Usage',
            data: new Array(chartData?.length).fill(uploadAverage),
            color: getColorCode('UploadUsage'), // Different color for average line
            dashStyle: 'Dash' // Optional: Makes it a dashed line
          }
        ],
    credits: {
      enabled: false
    },
    legend: { enabled: true },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (this.x !== undefined && this.y !== undefined) {
          const usagePoint = chartData[this.point.index]
          let tooltipContent = `<b>${this.x}</b><br/>`
          if (isDashboard) {
            tooltipContent += `Usage: ${Number(usagePoint.usage).toFixed(2)} MB<br/>`
            tooltipContent += `Device Count: ${usagePoint.deviceCount}`
          } else {
            if (this.series.name === 'Download Usage') {
              tooltipContent += `Download: ${usagePoint.downloadUsage.toFixed(2)} MB`
            } else if (this.series.name === 'Upload Usage') {
              tooltipContent += `Upload: ${usagePoint.uploadUsage.toFixed(2)} MB`
            } else if (this.series.name === 'Average Download Usage') {
              tooltipContent += `Avg Download: ${downloadAverage.toFixed(2)} MB`
            } else if (this.series.name === 'Average Upload Usage') {
              tooltipContent += `Avg Upload: ${uploadAverage.toFixed(2)} MB`
            }
          }
          return tooltipContent
        }
        return 'Invalid data'
      }
    }
  }

  // Filter columns to conditionally include the 'device' column based on isDashboard
  const filteredColumns = isDashboard ? columns : columns.filter(column => column.id !== 'device')

  return (
    <>
      <Modal
        width={'1200px'}
        centered
        footer={<></>}
        open={cellularModalOpen}
        onCancel={() => {
          setCellularModalOpen(false)
          setFilterData(24)
        }}
        closable={false}
      >
        <div className='text-end mb-2 '>
          <Close
            className='cursor-pointer'
            onClick={() => {
              setCellularModalOpen(false)
              setFilterData(24)
            }}
          />
        </div>
        <Card sx={{ width: '100%', margin: 'auto', boxShadow: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 10,
              marginTop: '0.4rem'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <Typography variant='h6' color='#3D4854'>
                Cellular Usage
              </Typography>

              {FilterData !== 'custom' && (
                <Typography variant='h6' color='#3D4854'>
                  {FilterData === 24
                    ? 'Last 24 Hours'
                    : FilterData === 1
                    ? 'Live Data'
                    : FilterData === 168
                    ? 'Last 7 Days'
                    : FilterData === 730
                    ? 'Last 30 Days'
                    : ''}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControl fullWidth>
                <Select
                  id='demo-simple-select'
                  value={FilterData}
                  onChange={handleChange}
                  className='text-uppercase'
                  size='small'
                >
                  <MenuItem value={1} className='text-uppercase'>
                    Live Data
                  </MenuItem>
                  <MenuItem value={24} className='text-uppercase'>
                    Last 24 Hours
                  </MenuItem>
                  <MenuItem value={168} className='text-uppercase'>
                    Last 7 Days
                  </MenuItem>
                  <MenuItem value={730} className='text-uppercase'>
                    Last 30 Days
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ marginTop: '1rem' }}>
            {cellularUsageDataFetchingGraph ? (
              <Loader />
            ) : (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
          </Box>

          <Card
            sx={{
              mt: 2,
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '0.5px solid #b4b8bd',
              boxShadow: 'none',
              borderRadius: '15px'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Box>
                <Typography color='#5D6670'>{isDashboard ? 'Cellular Usage' : 'Download Usage'}</Typography>
                <Typography
                  fontSize={'23px'}
                  color={isDashboard ? getColorCode('CellularUsage') : '#5D6670'}
                  fontWeight={700}
                >
                  {cellularUsageSummaryFetching ? (
                    <Skeleton variant='text' animation='wave' width={'auto'} height={32} />
                  ) : (
                    `${cellularUsageSummaryData?.download_data_mb || 0} MB`
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography color='#5D6670'>{isDashboard ? 'Cellular Usage' : 'Upload Usage'}</Typography>
                <Typography
                  fontSize={'23px'}
                  color={isDashboard ? getColorCode('CellularUsage') : '#5D6670'}
                  fontWeight={700}
                >
                  {cellularUsageSummaryFetching ? (
                    <Skeleton variant='text' animation='wave' width={'auto'} height={32} />
                  ) : (
                    `${cellularUsageSummaryData?.upload_data_mb || 0} MB`
                  )}
                </Typography>
              </Box>
            </Box>
            {isDashboard && (
              <div>
                <Typography color='#5D6670'>Devices Count</Typography>
                <Typography fontSize={'23px'} color={getColorCode('DeviceUsage')} fontWeight={700}>
                  322
                </Typography>
              </div>
            )}
          </Card>

          <CellularUsageTable filteredColumns={filteredColumns} tableData={tableData} isFetching={isFetching} />
        </Card>
      </Modal>
    </>
  )
}

export default CellularModal
