import GatewaySecurityLogs from './gateway-logs/gateway-security-logs'
import GatewayAdminActionsLogs from './gateway-logs/gateway-admin-actions-logs'
import GatewayActivityLogs from './gateway-logs/gateway-activity-logs'
import { useEffect } from 'react'
import CustomeTabs from '../../../components/CustomeTabs'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useGatewayServices } from '../../../hook/useGatewayServices'

const GatewayLogs = () => {
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data: gatewayInfo, refetch: gatewayInfoRef }: any = useGatewayInfo(gw_uid) //User device data
  const { data, refetch } = useGatewayServices(gw_uid)

  useEffect(() => {
    refetch()
  }, [gw_uid])

  const tabsList = [
    {
      label: 'Activity Logs',
      testId: 'testid_activity_logs',
      tab: (
        <>
          <GatewayActivityLogs />
        </>
      )
    },
    // Conditionally include the 'Security Logs' tab
    ...(data?.srv_cs_enabled
      ? [
          {
            label: 'Security Logs',
            testId: 'testid_security_logs',
            tab: (
              <>
                <GatewaySecurityLogs />
              </>
            )
          }
        ]
      : []),
    {
      label: 'Admin Logs',
      testId: 'testid_admin_logs',
      tab: (
        <>
          <GatewayAdminActionsLogs />
        </>
      )
    }
  ]

  useEffect(() => {
    gatewayInfoRef()
  }, [gw_uid])

  return (
    <>
      <CustomeTabs
        tabsList={
          gatewayInfo?.email === '' || gatewayInfo?.dev_mesh_role !== 'CAP'
            ? [
                {
                  label: ' Activity Logs',
                  testId: 'testid_activity_logs',
                  tab: (
                    <>
                      <GatewayActivityLogs />
                    </>
                  )
                },
                {
                  label: 'Admin Logs',
                  testId: 'testid_admin_logs',
                  tab: (
                    <>
                      <GatewayAdminActionsLogs />
                    </>
                  )
                }
              ]
            : tabsList
        }
        className='gate_way_logs'
      />
    </>
  )
}
export default GatewayLogs
