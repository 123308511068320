import React from 'react'
import { FormControl, Select, MenuItem } from '@mui/material'
import { wifiBandWidthProps } from '..'

interface BandwidthSelectProps {
  labelId: string
  value: string
  onChange: any
  options: wifiBandWidthProps[]
}

const BandwidthSelect: React.FC<BandwidthSelectProps> = ({ labelId, value, onChange, options }) => {
  return (
    <FormControl sx={{ minWidth: '190px' }} size='small'>
      <Select labelId={labelId} value={value} onChange={onChange} className='filter-select text-capitalize'>
        <MenuItem value='' selected={true} disabled={true}>
          <em>Select Band Width</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem value={option.value} className='text-capitalize' key={`option_${index}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default BandwidthSelect
