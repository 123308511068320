/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import CustomeTabs from '../../../../components/CustomeTabs'
import { getColorCode } from '../../../../utils/helper'
import DeviceTab from './deviceTab'
import CustomerTab from './customerTab'
import SessionTimeOutModal from '../../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../../SessionContex'
import { useModalReset, useSendMail } from '../../../../hook/device-control'
import { useGatewayInfo } from '../../../../hook/useGatewayInfo'
interface SnackBarProps {
  isOpen: boolean
  message: string
  type: 'redToRed' | 'Warning' | 'chartPurpleBlue' | 'Closed'
  severity?: 'error' | 'success'
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

interface CustomerDeviceInfoProps {
  customerinfo: any // Add a type annotation for the fillColor prop
  // setWanConnection: any
  // setFetchingRouterSettings: any
  // fetchingRouterSettings: any
  wanMqtt: any
  setWanMqtt: any
  gatewayFeaching: any
  mqttRes: any
  callMqtt?: any
  sendMqttMessage?: any
}
interface RouterConfig {
  SSID: string
  PASSWORD: string
  MESH_ROLE: string
}

const CustomerDeviceInfo: React.FC<CustomerDeviceInfoProps> = ({
  // fetchingRouterSettings,
  // setFetchingRouterSettings,
  // setWanConnection,
  customerinfo,
  wanMqtt,
  setWanMqtt,
  gatewayFeaching,
  mqttRes,
  callMqtt,
  sendMqttMessage
}) => {
  const { openModal, setOpenModal } = useSessionContex()
  const sentMailMutation = useSendMail()
  const resetMutation = useModalReset()
  const [fetchingRouterSettings, setFetchingRouterSettings] = useState<boolean>(true)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [editSSID, setEditSSID] = useState<boolean>(false)
  const [editPASSWORD, setEditPASSWORD] = useState<boolean>(false)
  const [apiCallPASSWORD, setApiCallPASSWORD] = useState<boolean>(false)
  const [mailPass, setMailPass] = useState('')
  const [defaultValues, setDefaultValues] = useState<any>()
  const [valuesChanged, setValuesChanged] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isApplyingConfig, setIsApplyingConfig] = useState<boolean>(false)
  const [country, setCountry] = useState(null)
  //handle form submits
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({ mode: 'onChange' })

  const watchFields = watch(['ssid', 'password'])

  const id = customerinfo?.gw_uid
  // const { refetch: gatewayRefetch }: any = useGatewayInfo(id)

  // const [mqtt, setMqtt] = useState<any>(null)
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })
  // const initializeMqtt = async () => {
  // const JsMqttClientImpl = (await import('../../../../mqtt')).JsMqttClientImpl
  // setMqtt(new JsMqttClientImpl())
  // }
  useEffect(() => {
    if (customerinfo === undefined) {
      setFetchingRouterSettings(true)
    }
    if (customerinfo === 401) {
      !openModal && setOpenModal(true)
    }
  }, [customerinfo])
  // useEffect(() => {
  //   if (!wanMqtt) {
  //     initializeMqtt()
  //   }
  // }, [id, wanMqtt])

  // useEffect(() => {
  //   console.log('=========wanMqtt', wanMqtt)

  //   setFetchingRouterSettings(true)
  // }, [wanMqtt])
  // const topic = `/router/${id}/command`
  // const topic2 = `/router/${id}/resp`
  // const topic3 = `/router/${id}/site_survey`

  const [routerConfig, setRouterConfig] = useState<RouterConfig>()
  // const message = JSON.stringify({
  //   cmd: 'getsysconfig',
  //   callid: 3,
  //   response_topic: 'resp',
  //   op: 'wizard',
  //   params: ['WLAN_SSID_2G', 'WLAN_password_2G', 'WLAN_SSID_5G', 'WLAN_password_5G', 'mesh_role', 'timezone_name']
  // })

  // generate payload for mqtt ( to change ssid , password )
  const generatePayload = (config: any) => {
    return {
      WLAN_SSID_2G: config.ssid,
      WLAN_SSID_5G: config.ssid,
      WLAN_password_2G: config.password,
      WLAN_password_5G: config.password
    }
  }
  // async function sendCommand(message: any, topic: string) {
  //   if (connectedToMqtt) {
  //     await mqtt.sendMessage({ message, topic })
  //   }
  // }

  // mqtt
  // useEffect(() => {
  //   if (customerinfo && !wanMqtt) {
  //     setConnectedToMqtt(false)
  //     setFetchingRouterSettings(true)

  //     if (customerinfo && customerinfo?.is_online && !connectedToMqtt && !wanMqtt) {
  //       mqtt
  //         ?.connect()
  //         .then(() => {
  //           setConnectedToMqtt(true)
  //           console.log('==========>>>>>>>>>>')
  //           setSnackBarInfo({
  //             isOpen: true,
  //             message: 'connected to Router',
  //             type: 'chartPurpleBlue'
  //           })
  //           mqtt.subscribeToTopics([topic2, topic, topic3])
  //           mqtt.sendMessage({ message, topic })
  //         })
  //         .catch((err: any) => {
  //           mqtt.disconnect()
  //           setSnackBarInfo({
  //             isOpen: true,
  //             message: 'Not connected to Router',
  //             type: 'redToRed'
  //           })

  //           setTimeout(() => {
  //             initializeMqtt()
  //           }, 500)
  //         })
  //     } else {
  //       if (customerinfo && !customerinfo?.is_online) {
  //         setFetchingRouterSettings(false)
  //         // setWanConnection('NA')
  //       }
  //     }
  //   }
  //   // return () => {
  //   //   if (mqtt !== null) {
  //   //     mqtt.disconnect()
  //   //   }
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mqtt, id, wanMqtt])

  useEffect(() => {
    if (apiCallPASSWORD && mailPass !== '') {
      if (process.env.REACT_APP_MODE === 'hitron') {
        mailFun(mailPass)
      } else {
        setApiCallPASSWORD(false)
        setMailPass('')
      }
    }
  }, [apiCallPASSWORD, mailPass])

  const mailFun = (pass: any) => {
    if (apiCallPASSWORD) {
      const mailPayload: any = {
        email: localStorage.getItem('email'),
        password: pass,
        gw_uuid: customerinfo?.gw_uid
      }
      if (mailPayload.email === '') {
        // setTimeout(() => {
        //   setSnackBarInfo({
        //     isOpen: true,
        //     message: 'Email not available',
        //     type: 'redToRed',
        //     severity: 'error'
        //   })
        // }, 6500)
      } else {
        sentMailMutation.mutate(
          { mailPayload },
          {
            onSuccess: (data: any) => {
              setSnackBarInfo({
                isOpen: true,
                message: 'Email notification will be sent to user on the reset password',
                type: 'Closed'
              })
              setApiCallPASSWORD(false)
              setMailPass('')
            },
            onError: (error: any) => {
              setSnackBarInfo({
                isOpen: true,
                message: error?.message,
                type: 'redToRed',
                severity: 'error'
              })
              setApiCallPASSWORD(false)
              setMailPass('')
            }
          }
        )
      }
    }
  }
  // useEffect(() => {
  //   if (mqtt) {
  //     mqtt?.addMessageListener((message: any) => {
  //       const res = JSON.parse(message)

  //       if (res.name === 'sysconfig') {
  //         if (
  //           res.callid === 3 &&
  //           res.params &&
  //           'WLAN_SSID_2G' in res.params &&
  //           'WLAN_password_2G' in res.params &&
  //           'WLAN_SSID_5G' in res.params &&
  //           'WLAN_password_5G' in res.params &&
  //           'mesh_role' in res.params &&
  //           'timezone_name' in res.params
  //         ) {
  //           console.log('==========res right ', res)
  //           const { WLAN_SSID_5G: SSID, WLAN_password_5G: PASSWORD, mesh_role: MESH_ROLE } = res.params
  //           setSnackBarInfo({
  //             isOpen: true,
  //             message: 'Fetched router configuration successfully',
  //             type: 'Closed'
  //           })
  //           setRouterConfig({ SSID: SSID, PASSWORD: PASSWORD, MESH_ROLE: MESH_ROLE })
  //           if (SSID) {
  //             setFetchingRouterSettings(false)
  //           }
  //           setCountry(res.params.timezone_name)
  //           // setWanConnection(
  //           //   res?.params?.access_method === 'ethernet'
  //           //     ? `WAN${res?.params?.cell_data?.sim_status === 'READY' ? ' with LTE Backup' : ''}`
  //           //     : res?.params?.access_method === 'lte'
  //           //     ? `LTE ${res?.params?.cell_data?.connection_label || ''}`
  //           //     : '-'
  //           // )
  //           // mqtt.disconnect()
  //           // setConnectedToMqtt(false)
  //         }

  //         if (res.callid === 356 && res.result === 'OK') {
  //           setSnackBarInfo({
  //             isOpen: true,
  //             message: 'Applied new config successfully',
  //             type: 'Closed'
  //           })
  //         }
  //         if (res.callid === 356 && res?.result === 'ERROR') {
  //           setSnackBarInfo({
  //             isOpen: true,
  //             message: res?.stderr_text,
  //             type: 'redToRed'
  //           })
  //         }
  //       }
  //     })
  //   }
  //   return () => {
  //     if (mqtt !== null) {
  //       mqtt.disconnect()
  //     }
  //   }
  // }, [mqtt, id])

  // mqtt
  // const [routerNewConfig, setRouterNewConfig] = useState(null)

  useEffect(() => {
    setFetchingRouterSettings(true)

    if (mqttRes) {
      if (mqttRes !== 'Offline') {
        const { WLAN_SSID_5G: SSID, WLAN_password_5G: PASSWORD, mesh_role: MESH_ROLE } = mqttRes?.params
        setSnackBarInfo({
          isOpen: true,
          message: 'Fetched router configuration successfully',
          type: 'Closed'
        })
        setRouterConfig({ SSID: SSID, PASSWORD: PASSWORD, MESH_ROLE: MESH_ROLE })
        setCountry(mqttRes?.params.timezone_name)
        setTimeout(() => {
          setFetchingRouterSettings(false)
        }, 500)
      }
      if (mqttRes === 'Offline') {
        setRouterConfig({ SSID: 'N/A', PASSWORD: 'N/A', MESH_ROLE: 'N/A' })
        setCountry(null)
        setTimeout(() => {
          setFetchingRouterSettings(false)
        }, 500)
      }
    }
  }, [mqttRes])

  const handleRouterConfigChange = (data: any) => {
    // console.log('==========mqttRes')
    if (editPASSWORD) {
      const payload = {
        _gw_uid: customerinfo.gw_uid,
        _2g_password: data.password,
        _5g_password: data.password
      }

      setFetchingRouterSettings(true)
      const url = 'webapp_gw_wifi_pass_reset'
      resetMutation.mutate(
        { url, payload },
        {
          onSuccess: (successdata: any) => {
            if (successdata === 401) {
              !openModal && setOpenModal(true)
            }

            setSnackBarInfo({
              isOpen: true,
              message: 'Applying new config',
              type: 'chartPurpleBlue'
            })

            const routerConfigPayload = JSON.stringify({
              cmd: 'setsysconfig',
              callid: 356,
              response_topic: 'resp',
              params: {
                WLAN_password_2G: data.password,
                WLAN_password_5G: data.password
              }
            })
            sendMqttMessage(routerConfigPayload, id)

            callMqtt()
            setApiCallPASSWORD(true)
            setIsApplyingConfig(false)
            return 'OK'
          },
          onError: (error: any) => {
            setSnackBarInfo({
              isOpen: true,
              message: error?.message,
              type: 'redToRed',
              severity: 'error'
            })
            return 'ERROR'
          }
        }
      )
    } else {
      setFetchingRouterSettings(true)
      setSnackBarInfo({
        isOpen: true,
        message: 'Applying new config',
        type: 'chartPurpleBlue'
      })

      const routerConfigPayload = JSON.stringify({
        cmd: 'setsysconfig',
        callid: 356,
        response_topic: 'resp',
        params: generatePayload(data)
      })
      setIsApplyingConfig(false)
      sendMqttMessage(routerConfigPayload, id)
    }
  }

  useEffect(() => {
    setValue('ssid', routerConfig?.SSID)
    setValue('password', routerConfig?.PASSWORD)

    setDefaultValues({
      ssid: routerConfig?.SSID,
      password: routerConfig?.PASSWORD
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerConfig])

  //
  useEffect(() => {
    // Compare form values with the default values to determine if there are changes
    const currentValues = getValues()
    let valuesChanged = false
    if (!isApplyingConfig) {
      valuesChanged = JSON.stringify(currentValues) !== JSON.stringify(defaultValues)
    }

    setValuesChanged(valuesChanged)
  }, [defaultValues, watchFields])

  const onSubmit = (data: any) => {
    setIsApplyingConfig(true)
    setEditSSID(false)
    setEditPASSWORD(false)
    setShowPassword(false)
    setValuesChanged(false)
    handleRouterConfigChange(data)
  }

  //handle form submits

  const handleSSIDEditInfo = () => {
    setEditSSID(!editSSID)
    setApiCallPASSWORD(false)
    setMailPass('')
  }
  const handlePasswordEditInfo = () => {
    setEditPASSWORD(!editPASSWORD)
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const hasErrors = Object.keys(errors).length > 0
  const inputClassName = classNames('ssidinput', {
    // eslint-disable-next-line no-useless-computed-key
    ['error']: false
  })
  const tabsList = [
    {
      label: 'Device',
      tab: (
        <>
          <DeviceTab
            customerinfo={customerinfo}
            gatewayFeaching={gatewayFeaching}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            fetchingRouterSettings={fetchingRouterSettings}
            handleShowPassword={handleShowPassword}
            showPassword={showPassword}
            editSSID={editSSID}
            routerConfig={routerConfig}
            mqttRes={mqttRes}
            handleSSIDEditInfo={handleSSIDEditInfo}
            valuesChanged={valuesChanged}
            hasErrors={hasErrors}
            register={register}
            errors={errors}
            inputClassName={inputClassName}
            editPASSWORD={editPASSWORD}
            handlePasswordEditInfo={handlePasswordEditInfo}
            setApiCallPASSWORD={setApiCallPASSWORD}
            setMailPass={setMailPass}
          />
        </>
      )
    }
    // {
    //   label: 'Customer',
    //   tab: (
    //     <>
    //       <CustomerTab region={country} fetchingRouterSettings={fetchingRouterSettings} />
    //     </>
    //   )
    // }
  ]

  return (
    <>
      <Card className='info-card-cls w-full'>
        <CardHeader className='info-header-cls' title='Device & Customer Information' />
        <CustomeTabs
          tabsList={tabsList}
          displayTab={false}
          snackbar={
            <Snackbar
              open={snackBarInfo.isOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              autoHideDuration={6000}
              onClose={() =>
                setSnackBarInfo({
                  isOpen: false,
                  message: snackBarInfo.message,
                  type: snackBarInfo.type
                })
              }
              sx={{ backgroundColor: 'transparent' }}
            >
              <div>
                <Alert
                  severity='success'
                  sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
                >
                  {snackBarInfo.message}
                </Alert>
              </div>
            </Snackbar>
          }
        />
      </Card>
    </>
  )
}

export default CustomerDeviceInfo
