import React from 'react'

const EmbedReport = () => {
  return (
    <iframe
      title='ods_data_view'
      width='100%'
      height='625'
      src='https://app.powerbi.com/reportEmbed?reportId=97ab9f97-e429-484e-bc02-298be208c043&autoAuth=true&ctid=5a132609-5a7a-4303-9f09-0005b4c609f4'
      frameBorder='0'
      allowFullScreen={true}
    ></iframe>
  )
}

export default EmbedReport
