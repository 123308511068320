import React, { ReactNode } from 'react'
import HeaderSection from './HeaderSection'
import { Box } from '@mui/material'
import FooterSection from './FooterSection'

type AnimatedLayoutProps = {
  children: ReactNode
}

const AnimatedLayout = ({ children }: AnimatedLayoutProps) => {
  return (
    <div className='anmated-layout-wrapper'>
      <HeaderSection />
      <Box
        sx={
          process.env.REACT_APP_MODE !== 'local' || process.env.REACT_APP_REALM === 'Customer'
            ? {
                height: process.env.REACT_APP_REALM === 'Customer' ? 'calc(100vh - 180px)' : 'calc(100vh - 206px)',
                display: 'block',
                overflowY: 'scroll'
              }
            : {
                display: 'unset',
                overflowY: 'unset'
              }
        }
      >
        {children}
      </Box>
      {(process.env.REACT_APP_MODE !== 'local' || process.env.REACT_APP_REALM === 'Customer') && <FooterSection />}
      {/* {process.env.REACT_APP_MODE !== 'local' && <FooterSection />} */}
    </div>
  )
}

export default AnimatedLayout
